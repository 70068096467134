import React from 'react'

const ServiceLabel = ({ service, separator = '—' }) => {
	if (!service) {
		return (<>News {separator} </>)
	}

	const category = { name: service[0]?.title, uri: service[0]?.uri }

	return (
		<>
			{category.name} {separator}
		</>
	)
}

export default ServiceLabel
