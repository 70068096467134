import { useStaticQuery, graphql } from 'gatsby'
const useSectorListQuery = (type = 'all') => {
	// const SectorListQuery = type => {
	const sectorData = useStaticQuery(graphql`
		query SectorListQuery {
			allWpService(
				sort: { fields: [title], order: ASC }
				filter: {locale: {locale: {eq: "en_US"}}}
			) {
				edges {
					sector: node {
						id
						databaseId
						uri
						slug
						title
						content
						excerpt
						serviceTypes {
							nodes {
								slug
								name
							}
						}
						serviceInformation {
							serviceLeader {
								... on WpPeople {
									id
									title
									excerpt
									content
									title
									slug
									uri
									featuredImage {
										node {
											localFile {
												childImageSharp {
													gatsbyImageData(
														layout: CONSTRAINED,
														aspectRatio: 0.8695652174,
														width: 300
													)
												}
											}
										}
									}
									jobTitles {
										nodes {
											name
										}
									}
								}
							}
						}
						featuredImage {
							node {
								localFile {
									childImageSharp {
										gatsbyImageData(
											layout: CONSTRAINED,
											aspectRatio: 0.94,
											width: 600
										)
									}
								}
							}
						}
						locale {
							id
							locale
						}
						translations {
							id
							locale
							href
						}
						contentType {
							node {
								name
							}
						}
					}
				}
			}
		}
	`)

	// Filter service by type
	return filterServiceType(sectorData, type)
}

const filterServiceType = (query, type) => {
	let queryData = query?.allWpService?.edges
	let data = []

	queryData.map((sector, i) => {
		let showType = false
		sector?.sector?.serviceTypes?.nodes.map(serviceType => {
			sector.sector.uri = sector.sector.uri.replace(
				'/service/',
				`/${serviceType?.slug}/`
			)
			showType = type === 'all' || serviceType?.slug === type
		})
		if (showType) data.push(sector)
	})
	return data
}

export default useSectorListQuery
