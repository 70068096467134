import React, {useState} from 'react'
import { graphql } from 'gatsby'
import { Container, Tabs, Tab } from 'react-bootstrap'
import Layout from '../components/Layout'
import HeroRight from '../components/HeroRight'
import OverviewText from '../components/OverviewText'
import ProAccordion from '../components/Accordion'
import TabWrapper from '../components/TabWrapper'
import IconLink from '../components/Common/StyledButton/IconLink'
import ProcopioForm from "../components/ProcopioForm";
import {useResourcesByService} from "../hooks/use-resources-by-service";
import {useEventsByService} from "../hooks/use-events-by-service";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import StyledCard from "../components/Common/StyledCard";
import EventCards from "../list/resources/Events/EventCards";
import PeopleGridView from "../components/PeopleList/PeopleGridView";
import Seo from 'gatsby-plugin-wpgraphql-seo';
import {useTestimoinalsByService} from "../hooks/use-testimonials-by-service";
import TestimonialGrid from "../components/FlexibleContent/TestimonialGrid";

const ServiceTemplate = ({ data: { post }, pageContext: { posts } }) => {
	const accordionData = post?.serviceInformation?.serviceAccordionPanel ?? []
	const overviewHeading = post?.serviceInformation?.serviceOverviewHeading
	const overviewContent = post?.serviceInformation?.serviceOverviewContent
	const resources = useResourcesByService(post.slug)
	const events = useEventsByService(post.slug)
	const testimonials = useTestimoinalsByService(post.slug)

	const seo = post?.seo
	if (seo?.metaDesc !== '') {
		const metaDescription =  overviewContent?.replace(/<[^>]+>/g, '').trim();
		seo.metaDesc = metaDescription
		seo.opengraphDescription = metaDescription
	}

	const [activeTab, setActiveTab] = useState('information');

	const handleContactClick = (e) => {
		setActiveTab('contact')
	}

	const handleActiveTab = (key) => {
		setActiveTab(key)
	}

	return (
		<Layout title={post.title}>
			<Seo post={{seo: seo}} />
			<Container className="pt-4" fluid="xl">
				<IconLink
					align="right"
					cta="Back to Services & Sectors"
					href="/services-and-sectors/"
					size="small"
					iconColor="orange"
					name="left"
				/>
			</Container>

			<HeroRight
				heading={post.title}
				content={post.content}
				langSelect={post.translations}
				langLocale={post.locale.locale}
				image={
					post?.featuredImage?.node?.localFile?.childImageSharp?.gatsbyImageData
				}
				ctaLink={'#sector-tabs-tab-contact'}
				ctaLabel={"Let's Talk"}
				ctaClick={handleContactClick}
			/>

			<TabWrapper>
				<Tabs defaultActiveKey="information" id="sector-tabs" activeKey={activeTab} onSelect={handleActiveTab} className="nav-tabs--alt">
					<Tab eventKey="information" title="Information">
						<>
              {overviewHeading && overviewContent && (
								<OverviewText title={overviewHeading} text={overviewContent} />
							)}
							{accordionData?.map(({ heading, content }, index) => (
										<ProAccordion
											title={heading}
											content={content}
											index={index + 1}
											key={index}
											first={index === 0}
										/>
								  ))}
						</>
					</Tab>
					<Tab eventKey="people" title="People">
						<>
							<PeopleGridView name="PeopleGridView" service={post.slug} leaders={post?.serviceInformation?.serviceLeader} />
						</>
					</Tab>
					{!!resources?.length && (
						<Tab eventKey="resources" title="Resources">
							<Row>
								{resources.map(({ resource }, index) => (
									<Col xs={6} md={4} key={`people-resource-${index}`}>
										<StyledCard
											variation="CardD"
											service={resource?.resourceInformation?.service}
											label={resource.resourceTypes?.nodes[0]?.name}
											labelId={resource.resourceTypes?.nodes[0]?.slug}
											href={resource.uri}
											eventDetails={null}
											image={
												resource.featuredImage?.node?.localFile?.childImageSharp
													?.gatsbyImageData
											}
											imageSize="medium"
											title={resource.title}
										/>
									</Col>
								))}
							</Row>
						</Tab>
					)}
					{!!events?.length &&
					<Tab eventKey="Events" title="Events">
						<Row>
							<EventCards events={events} title={null} />
						</Row>
					</Tab>
					}
					{!!testimonials?.length && (
						<Tab eventKey="testimonials" title="Testimonials">
							<TestimonialGrid testimonial={testimonials} />
						</Tab>
					)}
					<Tab eventKey="contact" title="Contact">
						<ProcopioForm presetValues={{
							'Firm Notes': `Service/Sector Contacted: ${post.title}`
						}} />
					</Tab>
				</Tabs>
			</TabWrapper>

		</Layout>
	)
}

export default ServiceTemplate

export const pageQuery = graphql`
	query ServiceById(
		# these variables are passed in via createPage.pageContext in gatsby-node.js
		$id: String!
	) {
		# selecting the current post by id
		post: wpService(id: { eq: $id }) {
			id
			excerpt
			content
			title
			slug
			date(formatString: "MMMM DD, YYYY")
			seo {
				...SeoFields
			}
			serviceInformation {
				serviceLeader {
					... on WpPeople {
						id
						slug
						title
						uri
					}
				}
				serviceOverviewHeading
				serviceOverviewContent
				serviceAccordionPanel {
					heading
					content
				}
			}
			locale {
				id
				locale
			}
			translations {
				id
				locale
				href
			}
			featuredImage {
				node {
					altText
					localFile {
						childImageSharp {
							gatsbyImageData(
								layout: CONSTRAINED,
								aspectRatio: 1.6,
								width: 1000
							)
						}
					}
				}
			}
		}
	}
`
