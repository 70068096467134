import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from '../components/Button'
import GeneralAccordion from '../components/FlexibleContent/GeneralAccordion'
import MediaContact from '../components/FlexibleContent/MediaContact'
import GeneralContent from '../components/FlexibleContent/GeneralContent'
import Link from '../components/Link'
import QuickSearch from '../components/FlexibleContent/QuickSearch'
import ResourcesByCategory from '../components/FlexibleContent/ResourcesByCategory'
import SocialSharing from '../components/SocialSharing'
import StyledCard from '../components/Common/StyledCard'
import ResourceRowPodcast from '../components/ResourceRowPodcast'
import RelatedContent from '../components/Resources/RelatedContent'
import BtnLink from '../components/Common/StyledButton/BtnLink'
import StyledButton from '../components/Common/StyledButton'

const Styleguide = ({ data }) => {
	const siteTitle = data.site.siteMetadata.title

	const genericContent = `<h1>General Content</h1><br/>\n<p>Lorem ipsum dolor sit amet, <strong>consectetur adipiscing elit,</strong> sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit <a href=\"/\">anim id est laborum</a>.</p>\n<blockquote><p>&#8220;Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididua aliqua.&#8221;</p></blockquote>\n<br/><h3>Lorem ipsum dolor</h3>\n<p><em>Lorem ipsum dolor sit amet,</em> consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>\n<br/><h3>Lorem ipsum dolor</h3>\n<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do e<a href=\"/\">iusmod tempor incididunt</a> ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>`

	return (
		<Layout title={siteTitle}>
			<Container>
				<Row>
					<Col>
						<br />
						<br />
						<h1>
							<b>Styleguide</b>
						</h1>
					</Col>
				</Row>
			</Container>
			<br />
			<br />

			<ResourcesByCategory category={{slug: 'diversity-equity-and-inclusion'}}/>

			<Container>
				<Row>
					<Col>
						<h2>
							<b>Breakpoints</b>
						</h2>
					</Col>
				</Row>
				{/* <p> */} 
				
				{/*
				Div tags shouldn't be put in p tags. I think this would actually fit description list content: 
				 */}
					<div>{`
					Breakpoint	Class infix	Dimensions
					`}</div>

					<div>{`
					X-Small	None	<576px 
					`}</div>

					<div>{`
					Small	sm	≥576px
					`}</div>

					<div>{`
					Medium	md	≥768px
					`}</div>

					<div>{`
					Large	lg	≥992px
					`}</div>

					<div>{`
					Extra large	xl	≥1200px
					`}</div>

					<div>{`
					Extra extra large	xxl	≥1400px
					`}</div>
				{/* </p> */}
				<br />
				<br />
				<Row>
					<Col>
						<h2>
							<b>Typography</b>
						</h2>
					</Col>
				</Row>

				<h1>Heading 1</h1>
				<h2>Heading 2</h2>
				<h3>Heading 3</h3>
				<h4>Heading 4</h4>
				<h5>Heading 5</h5>
				<p>
					Lorem ipsum dolor sit amet, consectetur adipisicing elit. Accusamus
					delectus ducimus enim fuga laborum mollitia odit quae recusandae saepe
					tenetur. Accusantium adipisci asperiores beatae commodi dicta
					dignissimos doloribus ex exercitationem itaque magnam minima,
					molestiae nemo nostrum omnis quaerat quos rem ut voluptatibus. Ad
					aliquid dicta eum inventore quia rerum tempora!
				</p>
			</Container>

			<br />
			<br />
			<Container>
				<Row>
					<h2>
						<b>Buttons and links</b>
					</h2>
				</Row>

				<Row>
					<Col>
						<Button
							buttonText="HTML Button CTA with super Long text"
							theme={'white'}
						/>
						<br />
						<br />
						<Button
							buttonText="Internal Link"
							to="/styleguide"
							theme={'black'}
							size={'small'}
						/>
						<br />
						<br />
						<Button
							buttonText="External Link"
							href="www.google.com"
							size={'large'}
						/>
						<br />
						<br />
						<Button buttonText="HTML Button" theme={'black'} size={'large'} />
						<br />
						<br />
						<Button
							buttonText="Gatsby Link"
							to="/styleguide"
							size={'small'}
							theme={'gray'}
						/>
						<br />
						<br />
						<div style={{ backgroundColor: '#555', padding: '30px' }}>
							<Button
								buttonText="Type Submit"
								type="submit"
								theme={'light gray'}
								size={'small'}
							/>
						</div>
						<br />
						<br />
						<div style={{ backgroundColor: '#ddd', padding: '30px' }}>
							<BtnLink useButton={true} cta="read case study" />
							<BtnLink
								useButton={true}
								cta="read case study"
								variant="secondary"
							/>
							<BtnLink useButton={true} cta="read case study" variant="white" />
						</div>
						<StyledButton href="/" cta="More Info" variant="white" />
					</Col>
				</Row>

				<br />

				<SocialSharing />

				<br />
				<br />
			</Container>

			<Container>
				<Row>
					<Col>
						<h2>
							<b>Blocks</b>
						</h2>
					</Col>
				</Row>
				<br />
				<br />
				<br />
				<h3>Media Contacts</h3>
				<MediaContact
					headingText="Stay up-to-date with the <i>Procopio newsletter.</i>"
					newsletterCta={{
						newsletterHeading: 'Stay up-to-date with the Procopio newsletter.',
						ctaText: 'Sign up Now',
					}}
					contactPerson={[
						{
							contactType: 'Media Contact',
							contactNameTitle:
								'Patrick Ross, Senior Manager of Marketing & Communications',
							contactEmail: 'email@email.com',
							contactPhone: '555-555-5555',
							contactFax: '555-555-5555',
							accessibleContactPhone: '555 555 5555', // should not have special chars and have spaces between number sets
						},
						{
							contactType: 'Events Contact',
							contactNameTitle: 'Valerie H. Sanderson Skoberm Events Planner',
							contactEmail: 'email@email.com',
							contactPhone: '555-555-5555',
							accessibleContactPhone: '555 555 5555',
						},
						{
							contactType: 'Additional Contact',
							contactNameTitle: 'Lorem Ipsum, Events Planner',
							contactPhone: '555-555-5555',
							accessibleContactPhone: '555 555 5555',
							contactFax: '111-222-3333',
						},
					]}
				/>

				<h3>General Accordion</h3>
				<br />
				<br />
				<GeneralAccordion
					accordion={[
						{
							accordionTitle: 'Lorem 1',
							accordionContent:
								'Lorem ipsum dolor sit amet consectetur adipisicing elit. Sed impedit corrupti numquam laudantium distinctio eius quas praesentium quos nihil? Accusantium adipisci molestias quasi qui dolor tenetur modi soluta voluptatum dignissimos?',
							optionalCta: {
								ctaLink: 'test.email@procopio.com',
								ctaText: 'Email link',
							},
						},
						{
							accordionTitle: 'Lorem 2',
							accordionContent:
								'Lorem ipsum dolor sit amet consectetur adipisicing elit. Sed impedit corrupti numquam laudantium distinctio eius quas praesentium quos nihil? Accusantium adipisci molestias quasi qui dolor tenetur modi soluta voluptatum dignissimos?',
							optionalCta: {
								ctaLink: 'attorneyrecruiting@procopio.com',
								ctaText: 'Email link',
							},
						},
						{
							accordionTitle: 'Lorem 3',
							accordionContent:
								'Lorem ipsum dolor sit amet consectetur adipisicing elit. Sed impedit corrupti numquam laudantium distinctio eius quas praesentium quos nihil? Accusantium adipisci molestias quasi qui dolor tenetur modi soluta voluptatum dignissimos?',
							optionalCta: {
								ctaLink: 'www.google.com',
								ctaText: 'External Link',
							},
						},
						{
							accordionTitle: 'Lorem 4',
							accordionContent:
								'Lorem ipsum dolor sit amet consectetur adipisicing elit. Sed impedit corrupti numquam laudantium distinctio eius quas praesentium quos nihil? Accusantium adipisci molestias quasi qui dolor tenetur modi soluta voluptatum dignissimos?',
							optionalCta: {
								ctaLink: '/test',
								ctaText: 'Internal Link',
							},
						},
						{
							accordionTitle: 'Lorem 5',
							accordionContent:
								'Lorem ipsum dolor sit amet consectetur adipisicing elit. Sed impedit corrupti numquam laudantium distinctio eius quas praesentium quos nihil? Accusantium adipisci molestias quasi qui dolor tenetur modi soluta voluptatum dignissimos?',
							optionalCta: {
								ctaLink: null,
								ctaText: null,
							},
						},
					]}
				/>
				<br />
				<br />
				<h3>Quick Search</h3>
				<QuickSearch
					headingText="Find your People"
					introCopy="With a roster of nearly 200 experienced attorneys servicing more than 40 service areas, Procopio helps businesses of all types excel."
					links={[
						{
							text: 'View People',
							href: '/people/',
						},
						{
							text: 'View Services & Sectors',
							href: '/services-and-sectors/',
						},
					]}
					ctaButton={{
						text: 'Get In Touch',
						href: '/contact/',
					}}
				/>
				<br />
				<br />
				<br />
				<h3>Revision</h3>
				<br />
				<Row>
					<Col xs={12} lg={7}>
						<StyledCard
							service={[{title: 'Aviation', uri: '#'}]}
							label="Videos"
							labelId="videos"
							href="/about"
							isFeatured={true}
							title="Lorem ipsum dolor"
							headingSize="title--lg"
							image="../../../images/ashim-d-silva-pGcqw1ARGyg-unsplash.png"
							eventDetails={null}
							imageSize="large"
						/>
					</Col>
					<Col xs={12} lg={5}>
						<Row>
							{[...Array(4)].map((item, index) => {
								return (
									<Col xs={6} sm={12} key={index}>
										<StyledCard
											variation="CardB"
											label="Articles"
											labelId="articles"
											href="/about"
											title={
												'What a New COVID-19 Legal Order Means for San Diego County’s Hospitality Industry ' +
												index
											}
											image="../../../images/ashim-d-silva-pGcqw1ARGyg-unsplash.png"
											eventDetails={null}
											imageSize="small"
										/>
									</Col>
								)
							})}
						</Row>
					</Col>
				</Row>
				<br />
				<br />
				<Row>
					<h3>Recent</h3>
				</Row>
				<br />
				<Row>
					{[...Array(6)].map((item, index) => {
						return (
							<Col sm={4} key={index}>
								<StyledCard
									variation="CardE"
									label="Articles"
									labelId="articles"
									href="/about"
									eventDetails={null}
									title={
										'What a New COVID-19 Legal Order Means for San Diego County’s Hospitality Industry ' +
										index
									}
								/>
							</Col>
						)
					})}
				</Row>

				<br />
				<br />
				<br />
				<br />

				<Row>
					<h3>Upcoming Events</h3>
				</Row>
				<br />
				<Row>
					{[...Array(3)].map((item, index) => {
						return (
							<Col sm={4} key={index}>
								<StyledCard
									variation="CardC"
									label="Articles"
									labelId="articles"
									href="/about"
									eventDetails={{
										date: 'January 30 at 8AM PST',
										location: 'San Diego, CA',
									}}
									image="../../../images/ashim-d-silva-pGcqw1ARGyg-unsplash.png"
									imageSize="medium"
									title={
										'What a New COVID-19 Legal Order Means for San Diego County’s Hospitality Industry ' +
										index
									}
								/>
							</Col>
						)
					})}
				</Row>

				<br />
				<br />
				<br />
				{/* <Row>
					<h2>Related Content</h2>
				</Row>
				<Row className="g-1">
					<RelatedContent />
				</Row> */}

				<Row>
					<h3>Podcasts</h3>
				</Row>
				<br />
				<Row>
					{[...Array(6)].map((item, index) => {
						return (
							<Col sm={3} key={index}>
								<StyledCard
									key={index}
									variation="CardF"
									label={null}
									href="/about"
									eventDetails={null}
									image="../../../images/ashim-d-silva-pGcqw1ARGyg-unsplash.png"
									imageSize="medium-square"
									time="2 hours"
									play={true}
									title={
										'What a New COVID-19 Legal Order Means for San Diego County’s Hospitality Industry ' +
										index
									}
								/>
							</Col>
						)
					})}
				</Row>

				<br />
				<br />

				<div>
					{[...Array(6)].map((item, index) => {
						return (
							<ResourceRowPodcast
								key={index}
								href="/about"
								time="2 hours"
								title="What a New COVID-19 Legal Order Means for San Diego County’s Hospitality Industry"
							/>
						)
					})}
				</div>

				<br />
				<br />
				<br />
				<Row>
					<h3>Video (Small)</h3>
				</Row>
				<br />
				<Row>
					{[...Array(3)].map((item, index) => {
						return (
							<Col sm={4} key={index}>
								<StyledCard
									variation="VideoSmall"
									href="/about"
									title="Cras justo odio dapibus ac facilisis in egestas"
									image="../../../images/ashim-d-silva-pGcqw1ARGyg-unsplash.png"
									eventDetails={null}
									imageSize="medium"
									time="2 hours"
								/>
							</Col>
						)
					})}
				</Row>

				<br />
				<br />
				<br />
				<Row>
					<h3>Video (Large)</h3>
				</Row>
				<br />
				<StyledCard
					variation="VideoLarge"
					category="Category"
					headingSize="title--lg"
					href="/about"
					title="Cras justo odio dapibus ac facilisis in egestas"
					image="../../../images/ashim-d-silva-pGcqw1ARGyg-unsplash.png"
					eventDetails={null}
					imageSize="medium"
					time="2 hours"
					play={true}
					upNext={{
						title: 'Up Next',
						items: [
							{
								href: '/about',
								title: 'Cras justo odio dapibus ac facilisis in egestas',
								image: '../../../images/ashim-d-silva-pGcqw1ARGyg-unsplash.png',
								time: '2 hours',
							},
						],
					}}
				/>
			</Container>
			<br />
			<br />
			<br />
			<br />

			<Container>
				<Row>
					<h3>General Content</h3>
				</Row>
			</Container>

			<GeneralContent content={genericContent} />
		</Layout>
	)
}

export default Styleguide

export const query = graphql`
	{
		site {
			siteMetadata {
				title
			}
		}
	}
`
