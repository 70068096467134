import React from 'react'
import ManagementTabRow from '../ManagementTabRow'
import useSectorListQuery from '../../../../hooks/use-sector-list-query'

const ListManagers = () => {
	const sectorData = useSectorListQuery()

	return (
		<>
			{sectorData
				? sectorData.map(data => {
						const sector = data.sector

					return (
							<div key={`mgmt-tab-row-${sector?.slug}`}>
								{sector?.serviceInformation?.serviceLeader && (
									<ManagementTabRow
										teamLabel={sector.title}
										leaders={sector.serviceInformation.serviceLeader}
									/>
								)}
							</div>
						)
				  })
				: null}
		</>
	)
}

export default ListManagers
